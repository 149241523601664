exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-abbonamenti-e-pass-tsx": () => import("./../../../src/pages/abbonamenti-e-pass.tsx" /* webpackChunkName: "component---src-pages-abbonamenti-e-pass-tsx" */),
  "component---src-pages-area-riservata-tsx": () => import("./../../../src/pages/area-riservata.tsx" /* webpackChunkName: "component---src-pages-area-riservata-tsx" */),
  "component---src-pages-bicipark-tsx": () => import("./../../../src/pages/bicipark.tsx" /* webpackChunkName: "component---src-pages-bicipark-tsx" */),
  "component---src-pages-chi-siamo-tsx": () => import("./../../../src/pages/chi-siamo.tsx" /* webpackChunkName: "component---src-pages-chi-siamo-tsx" */),
  "component---src-pages-contatti-tsx": () => import("./../../../src/pages/contatti.tsx" /* webpackChunkName: "component---src-pages-contatti-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-modulistica-tsx": () => import("./../../../src/pages/modulistica.tsx" /* webpackChunkName: "component---src-pages-modulistica-tsx" */),
  "component---src-pages-paga-la-penale-tsx": () => import("./../../../src/pages/paga-la-penale.tsx" /* webpackChunkName: "component---src-pages-paga-la-penale-tsx" */),
  "component---src-pages-paga-la-sosta-tsx": () => import("./../../../src/pages/paga-la-sosta.tsx" /* webpackChunkName: "component---src-pages-paga-la-sosta-tsx" */),
  "component---src-pages-parcheggi-di-intercambio-e-camper-tsx": () => import("./../../../src/pages/parcheggi-di-intercambio-e-camper.tsx" /* webpackChunkName: "component---src-pages-parcheggi-di-intercambio-e-camper-tsx" */),
  "component---src-pages-sosta-in-struttura-e-a-sbarra-tsx": () => import("./../../../src/pages/sosta-in-struttura-e-a-sbarra.tsx" /* webpackChunkName: "component---src-pages-sosta-in-struttura-e-a-sbarra-tsx" */),
  "component---src-pages-tariffe-e-orari-tsx": () => import("./../../../src/pages/tariffe-e-orari.tsx" /* webpackChunkName: "component---src-pages-tariffe-e-orari-tsx" */)
}

